import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DynamicForm from "./DynamicForm";
import { Grid, Box, Button, Typography } from "@mui/material";
import AlertModal from "../AlertModal";
import { fetchTableData } from "../../slice/TableSlice";
import { submitChargerData } from "../../slice/SubmitChargerSlice";
import { fetchStationList } from "../../slice/StationListSlice";
import { submitStationData } from "../../slice/SubmitStationSlice";
import { submitUserData } from "../../slice/SubmitUserSlice";
import { submitEditStationData } from "../../slice/SubmitEditStationSlice";
import { submitEditChargerData } from "../../slice/SubmitEditChargerSlice";
import { submitEditUserData } from "../../slice/SubmitEditUserSlice";
import { submitAdminData } from "../../slice/SubmitAdminSlice";
import { submitEditAdminData } from "../../slice/SubmitEditAdminSlice";
import { submitEditCouponData } from "../../slice/SubmitEditCouponSlice";
import { submitAddCouponData } from "../../slice/SubmitAddCoupon";
import { fetchCouponList } from "../../slice/CouponListSlice";

const CustomForm = ({ props }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [tabValue, setTabValue] = useState(0);
  const [mergedFormData, setMergedFormData] = useState(props.data);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {userId} = useSelector(state => state.editUser);
  const { selectedFacility } = useSelector(state => state.stationList);

  useEffect(() => {
    setCurrentTab(tabValue);
  }, [tabValue]);

  useEffect(() => {
  }, [mergedFormData]);

  useEffect(() => {
    setMergedFormData(props.data);
  }, [props.data]);

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
    if (newValue > currentTab) {
      setCurrentTab(currentTab + 1);
    } else if (newValue < currentTab) {
      setCurrentTab(currentTab - 1);
    }
  };

  const handleShowAlert = (message, type) => {
    setAlertMessage(message);
    setAlertType(type);
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleNext = () => {
    if (currentTab < props.data?.tabs.length - 1) {
      setCurrentTab(currentTab + 1);
    }
  };

  const handleBack = () => {
    if (currentTab > 0) {
      setCurrentTab(currentTab - 1);
    }
  };

  const handleDynamicAction = () => {
    if (props.modalType === "addStation" || props.modalType === "editStation") {
      dispatch(fetchStationList());
    } else if (
      props.modalType === "addCharger" ||
      props.modalType === "addUser" ||
      props.modalType === "editCharger" ||
      props.modalType === "editUser" ||
      props.modalType === "addAdmin" || 
      props.modalType === "editAdmin"
    ) {
      dispatch(fetchTableData(props.id));
    }
    else if (props.modalType === "addCoupon" || props.modalType === "editCoupon")
    dispatch(fetchCouponList());
  };

  const handleSave = async () => {

    try {
      const payload = mergedFormData ;
      let action;

      switch (props.modalType) {
        case "addStation":
          action = await dispatch(submitStationData(payload));
          break;
        case "addCharger":
          action = await dispatch(
            submitChargerData({ stationId: props.id, payload })
          );
          break;
        case "addUser":
          action = await dispatch(submitUserData({ stationId: props.id, payload }));
          break;
        case "addAdmin":
          action = await dispatch(submitAdminData({ stationId: props.id, payload }));
          break;
        case "editStation":
          action = await dispatch(
            submitEditStationData({ stationId: props.id, payload })
          );
          break;
        case "editCharger":
          let charger_id = localStorage.getItem("editChargerEndpoint").split("/")[2]
          if(props.id !== undefined)
            charger_id = props.id
          action = await dispatch(
            submitEditChargerData({ chargerId: charger_id, payload })
          );
          break;
        case "editUser":
          action = await dispatch(submitEditUserData({ userId: userId, payload }));
          break;
        case "editAdmin":
          action = await dispatch(submitEditAdminData({ userId: userId, payload }));
          break;
        case "editCoupon":
            action = await dispatch(submitEditCouponData({ couponId: props.id, payload }));
            break;   
        case "addCoupon":
              action = await dispatch(submitAddCouponData({ payload }));
              break;   
        default:
          break;
      }
      const responseData = action.payload;

      if (props.onModalClose && responseData.success) {
        handleShowAlert("Data added", "success");
      } else {
        handleShowAlert("Could not save data", "error");
      }
      if(responseData.success){
        if(selectedFacility?.id !== null && selectedFacility?.id !== undefined && selectedFacility?.id !== ""){ 
          dispatch(fetchTableData(selectedFacility?.id, null, null));
        }
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      // Check if there is an API error
    }
  };

  const handleFormValuesChange = (tab, name, value) => {
    if (name !== undefined) {
      setMergedFormData(prevFormData => {
        const newFormData = JSON.parse(JSON.stringify(prevFormData));
        newFormData.tabs[tab].form_input.forEach(item => {
          if (item.name === name) {
            item.value = value;
          }
        });
  
        return newFormData;
      });
    }
  };

  const stickyStyles = {
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
    height: "72px",
    display: "flex",
    zIndex: 111,
    justifyContent: (() => {
      if (props.data?.tabs.length > 1 && currentTab < props.data?.tabs.length - 1) {
        return "space-between";
      } else if (props.data?.tabs.length > 1 && currentTab > 0) {
        return "space-between";
      } else {
        return "flex-end";
      }
    })(),
    paddingTop: "16px",
    borderTop: "1px solid #EAEBEE",
  };

  return (
    <Box p={3}>
      <Grid container direction="column">
        <Typography variant="h6">{props.data?.name}</Typography>
        <DynamicForm
          props = {{
            formData: mergedFormData, 
            currentTab: currentTab, 
            onFormValuesChange: handleFormValuesChange,
            handleTabChange:handleTabChange}}
        />
        <Box style={stickyStyles}>
          {props.data?.tabs.length > 1 && currentTab > 0 && (
            <Button
              variant="outlined"
              style={{
                borderRadius: "28px",
                padding: "16px",
                height: "40px",
                backgroundColor: "#FFF",
                textTransform: "capitalize",
                borderColor: "#C0C4CD",
                color: "#001233",
              }}
              onClick={handleBack}
            >
              {t("back")}
            </Button>
          )}
          {props.data?.tabs.length > 1 && currentTab < props.data?.tabs.length - 1 && (
            <Button
              variant="contained"
              style={{
                borderRadius: "28px",
                padding: "16px",
                height: "40px",
                backgroundColor: "#467CEB",
                textTransform: "capitalize",
              }}
              onClick={handleNext}
            >
              {t("next")}
            </Button>
          )}
          {(props.data?.tabs.length === 1 ||
            (props.data?.tabs.length > 1 &&
              currentTab === props.data?.tabs.length - 1)) && !props.data?.disable_save_next_button
              && (
            <Button
              variant="contained"
              style={{
                borderRadius: "28px",
                padding: "16px",
                height: "40px",
                backgroundColor: "#467CEB",
                textTransform: "capitalize",
              }}
              onClick={handleSave}
            >
              {t("save")}
            </Button>
          )}
        </Box>
      </Grid>
      <AlertModal
        open={isAlertOpen}
        onClose={handleCloseAlert}
        message={alertMessage}
        alertType={alertType}
        onModalClose={props.onModalClose}
        dynamicDispatch={handleDynamicAction}
      />
    </Box>
  );
};

export default CustomForm;
