import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../apiSetup/apiCall';

export const fetchTableData = createAsyncThunk('station/fetchTableData', async ({stationId, startDate, endDate}) => {
  try {
      if(stationId===undefined) stationId = JSON.parse(localStorage.getItem("selectedStation")).id
      let url = `prometheus/facility/${stationId}/details/`;
      if (startDate) {
          url += `${startDate}/`;
      }
      if (endDate) {
          url += `${endDate}/`;
      }
      const response = await api.get(url);
      return response.data;
  } catch (error) {
      console.error('Error fetching facility details:', error);
      throw error;
  }
});


const TableSlice = createSlice({
  name: 'table',
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTableData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTableData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchTableData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default TableSlice.reducer;
