import axios from 'axios';
import config from '../config';

const BASE_URL = config.BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data && error.response.data.code === 'ER0x1103') {
      localStorage.clear();
      var event = new Event('localStorageCleared');
      window.dispatchEvent(event);
      window.location.pathname='/login';
    }
    return Promise.reject(error);
  }
);

export default api;
