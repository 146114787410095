import React from "react";
import { Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AddAdminButton = ({ onClick }) => (
  <Grid
    onClick={onClick}
    style={{
      display: "flex",
      height: "40px",
      padding: "16px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      border: "1px solid var(--light-mode-gray-30, #EAEBEE)",
      borderRadius: "28px",
      background: "var(--brand-400, #467CEB)",
      cursor: "pointer",
    }}
  >
    <AddIcon
      style={{
        color: "var(--color-styles-white, #FFF)",
        display: "flex",
        width: "14px",
        height: "14px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    />
    <Typography
      style={{
        color: "var(--color-styles-white, #FFF)",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "18px",
      }}
    >
      Add Admin
    </Typography>
  </Grid>
);

export default AddAdminButton;

