import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, CircularProgress } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import ProfileSection from "../ProfileSection";
import DynamicTable from "../customComponents/DynamicTable";
import DetailsTable from "../customComponents/DetailsTable";
import CustomTabs from "../customComponents/CustomTabs";
import DynamicFormModal from "../customComponents/DynamicFormModal";
import AddChargerButton from "../buttonComponents/AddChargerButton";
import SearchButton from "../buttonComponents/SearchButton";
import ExportExcel from "../buttonComponents/ExportExcel";
import DownloadButton from "../buttonComponents/DownloadButton";
import * as XLSX from "xlsx";
import { useSelector, useDispatch } from "react-redux";
import { fetchBillingTableData } from "../../slice/BillingTableSlice";
import CustomMonthYearPicker from "../customComponents/CustomMonthYearPicker";
import FacilityFilterBtn from "../FacilityFilter";
import CustomBox from "../customComponents/CustomBox";
import dayjs from 'dayjs';

const BillingAndPayment = () => {
  const [selectedBill, setSelectedBill] = useState(null);
  const [searchedBilledData, setSearchedBilledData] = useState([]);
  const [tableSearchText, setTableSearchText] = useState("");
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [dynamicModalType, setDynamicModalType] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  const billingTableData = useSelector((state) => state.billingTable.data.data);
  const billingDetailsData = useSelector((state) => state.billingDetails.data.data);
  const billingStatus = useSelector((state) => state.billingTable);
  const billingDetailsStatus = useSelector((state) => state.billingDetails);
  const selectedFacility = useSelector((state) => state.stationList.selectedFacility);
  const selectedMonthYear = useSelector((state) => state.billingTable.selectedMonthYear);
  const [monthName, year] = selectedMonthYear.split(' ');
  const monthNumber = dayjs(`${monthName} 1, ${year}`, 'MMMM D, YYYY').format('M');

  const dispatch = useDispatch();

  const isTableLoading = billingStatus.isLoading || billingDetailsStatus.isLoading;

  const apiTabs = billingTableData && billingTableData.tabs ? billingTableData.tabs : [];

  useEffect(() => {
    const currentTabData = billingTableData?.tabs[tabValue];
    setSearchedBilledData(currentTabData ? currentTabData.table_content : []);
  }, [billingTableData, tabValue]);

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
    const currentTabData = apiTabs[newValue];
    if (currentTabData && currentTabData.table_content) {
      setSearchedBilledData(currentTabData.table_content);
    }
  };

  const handleisFabModalOpen = () => {
    const newApiCallType = "addCharger";
    localStorage.setItem("dynamicModalType", newApiCallType);
    setDynamicModalType(newApiCallType);
    setIsDynamicModalOpen(true);
  };

  const handleShowLogsChange = (value) => {
    setShowDetails(value);
  };

  const handleBackArrowClick = () => {
    setShowDetails(false);
    setTableSearchText((prevSearchText) => prevSearchText);
  };

  const handleSearch = (searchText) => {
    setTableSearchText(searchText);
    if (searchText.trim() === "") {
      const currentTabData = apiTabs[tabValue];
      if (currentTabData && currentTabData.table_content) {
        setSearchedBilledData(currentTabData.table_content);
      }
    } else {
      const filteredBillData = searchedBilledData.filter((data) => {
        const searchTextLowerCase = searchText.toLowerCase();
        return Object.values(data).some(value => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(searchTextLowerCase);
          } else if (typeof value === "number") {
            return value.toString().toLowerCase().includes(searchTextLowerCase);
          } else if (value && typeof value === "object") {
            return Object.values(value).some(val => {
              if (typeof val === "string") {
                return val.toLowerCase().includes(searchTextLowerCase);
              } else if (typeof val === "number") {
                return val.toString().toLowerCase().includes(searchTextLowerCase);
              }
              return false;
            });
          }
          return false;
        });
      });

      setSearchedBilledData(filteredBillData);
    }
  };
  const handleSearchButton = (tabName, searchText) => {
    switch (tabName) {
      case "Bills":
        handleSearch(searchText);
        break;
      default:
        break;
    }
  };

  const handleExportToExcelClick = () => {
    const ws = XLSX.utils.json_to_sheet([]);
    const wb = XLSX.utils.book_new();

    const currentTabData = billingTableData.tabs[tabValue];

    const headers = currentTabData.headers
      .filter(
        (header) =>
          header.id !== "id" && header.id !== "edit" && header.id !== "currency"
      )
      .map((header) => header?.label);

    // Add headers to the worksheet
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

    const tableContent = currentTabData.table_content.map((item) => {
      const rowData = currentTabData.headers
        .filter(
          (header) =>
            header.id !== "id" &&
            header.id !== "edit" &&
            header.id !== "currency"
        )
        .map((header) => {
          const value = item[header.id];
          return typeof value === "object" ? value?.label : value;
        });
      return rowData;
    });

    // Add table content to the worksheet
    XLSX.utils.sheet_add_aoa(ws, tableContent, { origin: "A2" });

    const sheetName = currentTabData.name || "Sheet1";
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    // Save Excel file
    const fileName = `${sheetName.toLowerCase()}_data.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const renderButtons = () => {
    const currentTabData = apiTabs[tabValue];

    if (currentTabData && currentTabData.filters_and_buttons) {
      const placeholder = `Search ${currentTabData.name || "Item"}`;
      return currentTabData.filters_and_buttons.map((button, index) => {
        switch (button) {
          case "Add Charger":
            return (
              <AddChargerButton
                key={index}
                onClick={() => handleisFabModalOpen()}
              />
            );
          case "Search":
            return (
              <SearchButton
                key={index}
                placeholder={placeholder}
                handleOnSearch={(searchText) =>
                  handleSearchButton(currentTabData.name, searchText)
                }
                value={tableSearchText}
              />
            );
          case "Export to Excel":
            return (
              <ExportExcel key={index} onClick={handleExportToExcelClick} />
            );
          case "Download":
            return (
              <DownloadButton key={index} onClick={handleExportToExcelClick} />
            );
          default:
            return null;
        }
      });
    }
  };

  useEffect(() => {
    dispatch(fetchBillingTableData({
      facilityId: selectedFacility.name === "All" ? 0 : selectedFacility.id,
      selectedMonth: selectedMonthYear === "" ? 0 : monthNumber,
      selectedYear: selectedMonthYear === "" ? 0 : year
    }));
  }, [dispatch]);

  return (
    <>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={3}
        alignItems={"center"}
        marginLeft="-35px"
      >
        <Grid item xs={8} sx={{ flexGrow: 1 }}>
          <Typography variant="h4" fontSize="24px" fontWeight="600">
            Billing and Payment - Sessions
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <ProfileSection />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={3}
        sx={{ marginTop: 1, marginLeft: "-35px" }}
      >
        {isTableLoading ? (
          <Grid sx={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "0" }}
            style={{ paddingTop: "0" }}
          >
            <Grid item xs={12}>
              <Paper
                sx={{
                  borderRadius: "16px",
                  background: "none",
                  boxShadow: "none",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                  }}
                >
                  <Grid sx={{ width: "100%" }}>
                    <Grid
                      container
                      mb={2}
                      columnSpacing={2}
                      alignItems="flex-end"
                    >
                      <Grid item>
                        <CustomMonthYearPicker />
                      </Grid>
                      <Grid item>
                        <FacilityFilterBtn />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      rowSpacing={3}
                      columnSpacing={3}
                      sx={{ marginBottom: 2 }}
                    >
                      {billingTableData && billingTableData?.cards && Object.entries(billingTableData?.cards).map(([key, value]) => (
                        <Grid item key={key} xs={2.4}>
                          <CustomBox cardText={key} cardValue={value} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                {showDetails ? (
                  <>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        paddingTop: "16px",
                        paddingBottom: "32px",
                      }}
                    >
                      <ArrowBack
                        sx={{ cursor: "pointer" }}
                        onClick={handleBackArrowClick}
                      />
                      <Typography
                        sx={{
                          color: "var(--light-mode-gray-80, #001233)",
                          fontFamily: "Inter",
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        {billingDetailsData?.tabs[0].name}
                      </Typography>
                    </Grid>
                    {billingDetailsData?.tabs[0]?.table_content.length > 0 && (
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                      </Grid>
                    )}
                    <DetailsTable detailsData={billingDetailsData} />
                  </>
                ) : (
                  <Grid style={{ background: "white", borderRadius: "16px" }}>
                    <Grid style={{ padding: "8px 16px 0 16px" }}>
                      <CustomTabs
                        formTabs={apiTabs}
                        tabValue={tabValue}
                        handleTabChange={handleTabChange}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "12px",
                        margin: "16px 16px 0 16px",
                        justifyContent: "space-between",
                      }}
                    >
                      {showDetails ? null : renderButtons()}
                    </Grid>
                    <DynamicTable
                      setSelectedData={setSelectedBill}
                      selectedData={selectedBill}
                      tabValue={tabValue}
                      searchedData={searchedBilledData}
                      tabs={billingTableData?.tabs}
                      onEditClick={handleisFabModalOpen}
                      onShowLogsChange={handleShowLogsChange}
                    />
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
        <DynamicFormModal
          isOpen={isDynamicModalOpen}
          onClose={() => setIsDynamicModalOpen(false)}
          apiCallType={dynamicModalType}
        // id={id}
        />
      </Grid>
    </>
  );
};

export default BillingAndPayment;
