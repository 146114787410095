import React, {useEffect} from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Sidebar from "../Sidebar";
import { fetchUser } from "../../slice/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchStationList } from "../../slice/StationListSlice";
import { fetchCountryList } from "../../slice/CountrySlice";
import { toastContainer } from "../../common";

const MainLayout = ({ component: Component }) => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.data.data);

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchStationList());
  },[dispatch])

  useEffect(() => {
    if(userData?.facility_admin_type === "superadmin") {
      dispatch(fetchCountryList());
    }
  },[userData])
  
  return (
    <>
    {toastContainer()}
      <Grid container>
        <Grid item xs={12} style={{ display: "flex", gap: "8px", width: "100%" }}>
          <Sidebar />
          <Box component="main" sx={{ bgcolor: "background.default", p: 2 }} style={{width:"100%"}}>
            <Component />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MainLayout;
