import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../components/Login';
import StationManagement from '../components/StationMangement/StationManagement';
import MainLayout from '../components/dashboard/MainLayout';
import ChargingStations from '../components/ChargingStations';
import Overview from '../components/dashboard/Overview';
import BillingAndPayment from '../components/billingAndPayment/BillingAndPayment';
import UserManagement from '../components/userManagement/UserManagement';
import { useDispatch } from 'react-redux';
import { clearLoginState } from '../slice/LoginSlice';
import MapManagement from '../components/mapManagement/MapManagement';
import MyApp from '../components/myApp/MyApp';
import NotFound from '../components/404Page';
import Coupon from '../components/couponPage/Coupon';

const PrivateRoute = ({ element, isAuthenticated }) => {
  const isRootPath = window.location.pathname === '/';
  const dispatch = useDispatch();
  if (!isAuthenticated)
    dispatch(clearLoginState());

  return isAuthenticated ? (
    isRootPath ? (
      <Navigate to="/dashboard" replace />
    ) : (
      element
    )
  ) : (
    <Navigate to="/login" replace state={{ from: window.location.pathname }} />
  );
};

const AppRoutes = () => {

  let isAuthenticated = localStorage.getItem('token')?.length > 0;


  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute
            element={<Login />}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route
        path="/login"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <Login isAuthenticated={isAuthenticated} />
          )
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute
            element={<MainLayout component={Overview} />}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route
        path="/charging-stations"
        element={
          <PrivateRoute
            element={<MainLayout component={ChargingStations} />}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route
        path="/station"
        element={
          <PrivateRoute
            element={<MainLayout component={StationManagement} />}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route
        path="/billing-payment"
        element={
          <PrivateRoute
            element={<MainLayout component={BillingAndPayment} />}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route
        path="/user-management"
        element={
          <PrivateRoute
            element={<MainLayout component={UserManagement} />}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route
        path="/map"
        element={
          <PrivateRoute
            element={<MainLayout component={MapManagement} />}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route
        path="/my-app"
        element={
          <PrivateRoute
            element={<MainLayout component={MyApp} />}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route
        path="/coupon"
        element={
          <PrivateRoute
            element={<MainLayout component={Coupon} />}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
