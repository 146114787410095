import { useSubscription } from "@apollo/client";
import { API_TOTAL_REVENUE_FACILITY_SUBSCRIPTION, API_TOTAL_REVENUE_WITH_ALL_FACILITY_SUBSCRIPTION } from "../graphql/query";
import { useEffect, useState } from "react";
import RevenueIcon from "./dashboard/icons/RevenueIcon";
import {
    Typography,
    Box,
    Table,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Paper,
    TableContainer,
    Tooltip,
    IconButton
} from "@mui/material";
import { useSelector } from "react-redux";
import ReactCardFlip from "react-card-flip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import { useTranslation } from 'react-i18next';

const calculateUnits = (data) => {
    const { units_consumed, meter_start_at, meter_stop_at } = data;
    if (units_consumed >= 0)
        return units_consumed / 1000;
    else if (meter_start_at && meter_stop_at && meter_stop_at > meter_start_at)
        return (meter_stop_at - meter_start_at) / 1000;
    else {
        return 0;
    }
}

const findPrice = (data, station) => {
    return data.power_type === 'AC' ? station.price_per_unit_ac : station.price_per_unit_dc;
}


const calculateItemStats = (item, units_consumed, station, is_fleet = false) => {
    const electricityTotal = units_consumed * (station.price_per_unit_electricity ?? 0);
    const revenueTotal = is_fleet ? (units_consumed * findPrice(item, station)) : item.total_amount_spent;
    const profit = revenueTotal - electricityTotal;
    let companyProfit = 0;
    if (station.is_profit_sharing) {
        if (station.profit_sharing_type === 'Fixed')
            companyProfit = units_consumed * station.profit_sharing_amount;
        else
            companyProfit = (profit * station.profit_sharing_amount) / 100;
    }
    return { electricityTotal, revenueTotal, profit: profit - companyProfit, companyProfit, withoutCompanyProfit: profit };
}

const generateRevenuePayload = (obj, facility,) => {
    let arr = [];
    arr[0] = { type: 'AC', price: facility?.price_per_unit_ac, units: obj.units_consumed_ac, amount: (facility?.price_per_unit_ac * obj.units_consumed_ac)?.toFixed(2) };
    arr[1] = { type: 'DC', price: facility?.price_per_unit_dc, units: obj.units_consumed_dc, amount: (facility?.price_per_unit_dc * obj.units_consumed_dc)?.toFixed(2) };
    arr[2] = { type: 'Total', amount: obj.revenue?.toFixed(2), units: '-', price: '-' };
    return arr;
}

export const generateTotalRevenue = (data, stationList) => {
    let units_consumed = 0;
    let electricity_total = 0;
    let company_profit = 0;
    let profit = 0;
    let withoutCompanyProfit = 0;
    let units_consumed_ac = 0;
    let units_consumed_dc = 0;
    const results = data.reduce((acc, item) => {
        const station = stationList.find(itemm => item?.facility_id === itemm?.id);
        if (item.is_fleet) {
            const itemUnits = calculateUnits(item);
            const stats = calculateItemStats(item, itemUnits, station, true);
            if (item?.power_type === 'AC')
                units_consumed_ac += itemUnits;
            else
                units_consumed_dc += itemUnits;
            units_consumed += itemUnits;
            electricity_total += stats.electricityTotal;
            withoutCompanyProfit += stats.withoutCompanyProfit;
            company_profit += stats.companyProfit;
            profit += stats.profit;
            return acc + stats.revenueTotal;
        }
        else {
            const itemUnits = item?.units_consumed / 1000;
            const stats = calculateItemStats(item, itemUnits, station);
            units_consumed += itemUnits;
            electricity_total += stats.electricityTotal;
            company_profit += stats.companyProfit;
            withoutCompanyProfit += stats.withoutCompanyProfit;
            profit += stats.profit;
            return acc + stats.revenueTotal;
        }
    }, 0)
    units_consumed_ac = units_consumed_ac?.toFixed(2);
    units_consumed_dc = units_consumed_dc?.toFixed(2);
    return { revenue: results, electricity_total, company_profit, profit, units_consumed, units_consumed_ac, units_consumed_dc, withoutCompanyProfit };
}


const TotalRevenue = ({ dateFilter, facilityFilter }) => {

    const api = facilityFilter?.name === 'All' ?
        API_TOTAL_REVENUE_WITH_ALL_FACILITY_SUBSCRIPTION : API_TOTAL_REVENUE_FACILITY_SUBSCRIPTION;
    const variables = {
        variables: {
            start: dateFilter?.start?.toISOString(),
            end: dateFilter?.end?.toISOString(),
            facility_id: facilityFilter?.id
        }
    };

    const { data } = useSubscription(api, variables);
    const [revenue, setRevenue] = useState(0.00);
    const { currency } = useSelector(state => state.user);
    const stationlist = useSelector(state => state.stationList.data.data);
    const [isFlipped, setIsFlipped] = useState(false);
    const [rows, setRows] = useState(null);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const [id, setId] = useState(facilityFilter?.id)

    useEffect(() => setId(facilityFilter?.id), [facilityFilter])


    useEffect(() => {
        setRevenue(0);
    }, [id, dateFilter])

    useEffect(() => {
        setLoading(true);
        if (data?.view_facility_revenue) {
            const stats = generateTotalRevenue(data?.view_facility_revenue, stationlist);

            if (/^[0-9.]+$/.test(stats?.revenue)) {
                setRevenue(stats.revenue.toFixed(2));
            } else {
                setRevenue(0.00);
            }
            setLoading(false);
            if (facilityFilter?.is_fleet) {
                setRows(generateRevenuePayload(stats, facilityFilter));
            }
        }
    }, [data, dateFilter, facilityFilter, stationlist]);

    useEffect(() => {
        setIsFlipped(false);
    }, [dateFilter, facilityFilter])

    const handleFlip = () => {
        setIsFlipped(prev => !prev);
    }

    return (
        <Box>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" flipSpeedFrontToBack="0.6" flipSpeedBackToFront="0.6">
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    bgcolor={"white"}
                    justifyContent={"space-between"}
                    borderRadius={"16px"}
                    padding='24px'
                    height='130px'
                    key="front"
                >
                    <Typography >
                        <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>
                            {t('totalRevenue')}
                            <Typography variant="subtitle" fontSize='9px' color='grey' pl='3px'>
                                (incl. of all tax)
                            </Typography>
                            {loading ? ` (${t('loading')}...)` : ''}
                        </Typography>
                        <Typography variant="h5" fontSize='24px' fontWeight='700' sx={{ marginTop: '10px' }} style={{ textWrap: 'nowrap' }}>{`${currency} ${revenue}`}</Typography>
                    </Typography>
                    <RevenueIcon />
                    {
                        facilityFilter?.is_fleet &&
                        <Tooltip title="Info" sx={{ position: "absolute", top: "-3px", right: "0px", color: "grey" }} >
                            <IconButton>
                                <Box onClick={handleFlip} >
                                    <InfoOutlinedIcon />
                                </Box>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
                {facilityFilter?.is_fleet &&
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        borderRadius={"16px"}
                        height='130px'
                        key="back"
                        overflow="hidden"
                    >
                        <TableContainer component={Paper} sx={{ height: '100%', width: '100%', overflow: 'hidden', padding: '5px 5px 0', border: "none", backgroundColor: "grey", color: 'white' }}>
                            <Typography variant="h5" fontSize='12px' fontWeight='600' padding='5px' textAlign='center'>{t('totalRevenue')}</Typography>
                            <Table aria-label="simple table" sx={{ margin: '10px 0 0' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>{t('type')}</TableCell>
                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>{t('units')}&nbsp;(kWh)</TableCell>
                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>{currency || ' '}/kWh</TableCell>
                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>{t('amount')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows &&
                                        rows.map((row) => (
                                            <TableRow
                                                key={row.type}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" align="center" sx={{ padding: '2px', fontSize: "10px", color: 'white' }}>
                                                    {row.type}
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '2px', fontSize: '8px', color: 'white' }}>{row.units}</TableCell>
                                                <TableCell align="center" sx={{ padding: '2px', fontSize: '8px', color: 'white' }}>{row.type !== 'Total' ? (currency || ' ') : ' '}{row.price}</TableCell>
                                                <TableCell align="center" sx={{ padding: '2px', fontSize: '8px', color: 'white' }}>{currency || ' '}{row.amount}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Tooltip title="Rotate" sx={{ position: "absolute", top: "-3px", right: "0px", color: "white" }} >
                            <IconButton>
                                <Box onClick={handleFlip} >
                                    <ThreeSixtyIcon />
                                </Box>
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </ReactCardFlip>
        </Box >
    );
}
export default TotalRevenue;