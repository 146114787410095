import { useDispatch, useSelector } from "react-redux";
import { setSelectedFacility } from "../slice/StationListSlice";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { fetchBillingTableData } from "../slice/BillingTableSlice";
import dayjs from 'dayjs';
import { fetchUserTableData } from "../slice/UserTableSlice";

const FacilityFilterBtn = () => {
  const dispatch = useDispatch();
  const { selectedFacility, facilities } = useSelector((state) => state.stationList);
  const {selectedMonthYear} = useSelector(store => store.billingTable);
  const [monthName, year] = selectedMonthYear.split(' ');
  const monthNumber = dayjs(`${monthName} 1, ${year}`, 'MMMM D, YYYY').format('M');

  const changeHandler = (e) => {
    const selectedFacilityName = e.target.value;
    const selectedFacilityObject = facilities.find(
      (item) => item?.name === selectedFacilityName
    );
    dispatch(setSelectedFacility(selectedFacilityObject));
    console.log("selectedFacilityName");
    if (window.location.pathname === "/billing-payment") {
      dispatch(
        fetchBillingTableData({
          facilityId: selectedFacilityObject.name === "All" ? 0 : selectedFacilityObject?.id,
          selectedMonth: selectedMonthYear === "" ? 0 : monthNumber,
          selectedYear: selectedMonthYear === "" ? 0 : year,
        })
      );
    }
    else if (window.location.pathname === "/user-management") {
       dispatch(fetchUserTableData(selectedFacilityObject.name === "All" ? 0 : selectedFacilityObject?.id));
    }
  };

  return (
    <Grid container>
      <FormControl sx={{ minWidth: "150px" }}>
        <InputLabel id="month-select-label">Select Facility </InputLabel>
        <Select
          labelId="month-select-label"
          label="Select Facility "
          value={selectedFacility?.name}
          onChange={changeHandler}
          sx={{ height: "49px", backgroundColor: "white" }}
          style={{ fontSize: "15px" }}
        >
          {facilities &&
            facilities.map((item, index) => (
              <MenuItem key={index} value={item.name} sx={{ fontSize: "15px" }}>
                {index > 0 ? (
                  <>
                    {item.name} &nbsp; <b>[{item?.country}]</b>
                  </>
                ) : (
                  <>{item.name}</>
                )}
                {/* {`${selectedFacility?.name} [${selectedFacility?.country}]`} */}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
export default FacilityFilterBtn;
