import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastContainer = () => {
	return (
		<ToastContainer
			position="bottom-center"
			autoClose={800}
			hideProgressBar={false}
			newestOnTop={true}
			closeOnClick={true}
			rtl={false}
			pauseOnFocusLoss={false}
			draggable
			pauseOnHover
			theme="colored"
		/>
	);
};