import React, { useEffect } from "react";
import { Modal, Box, Paper, IconButton, Grid, CircularProgress, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const CustomAddModal = ({ isOpen, onClose, component, modalType }) => {

  const addStationStatus = useSelector((state) => state.addStation);
  const addChargerStatus = useSelector((state) => state.addCharger);
  const addUserStatus = useSelector((state) => state.addUser);
  const addAdminStatus = useSelector((state) => state.addAdmin);
  const editStationStatus = useSelector((state) => state.editStation);
  const editChargerStatus = useSelector((state) => state.editCharger);
  const editUserStatus = useSelector((state) => state.editUser);
  const editAdminStatus = useSelector((state) => state.editAdmin);
  const editCouponStatus = useSelector((state) => state.editCoupon);
  const addCouponStatus = useSelector((state) => state.addCoupon);
  const getConfigurationStatus = useSelector((state) => state.getConfiguration);

  const isFormLoading =
    addStationStatus.isLoading ||
    addChargerStatus.isLoading ||
    addUserStatus.isLoading ||
    addAdminStatus.isLoading ||
    editStationStatus.isLoading ||
    editChargerStatus.isLoading ||
    editUserStatus.isLoading ||
    editCouponStatus.isLoading ||
    addCouponStatus.isLoading ||
    editAdminStatus.isLoading||
    getConfigurationStatus.isLoading;

  const checkIsError = () => {
    let isError;
    switch (modalType) {
      case "addStation":
        isError = addStationStatus.error
        break;
      case "addCharger":
        isError = addChargerStatus.error
        break;
      case "addUser":
        isError = addUserStatus.error
        break;
      case "addCoupon":
        isError = addCouponStatus.error
        break;
      case "addAdmin":
        isError = addAdminStatus.error
        break;
      case "editStation":
        isError = editStationStatus.error
        break;
      case "editCharger":
        isError = editChargerStatus.error
        break;
      case "editUser":
        isError = editUserStatus.error
        break;
      case "editAdmin":
        isError = editAdminStatus.error
        break;
      case "editCoupon":
        isError = editCouponStatus.error
        break;
       case "getConfiguration":
          isError = getConfigurationStatus.error
          break;
      default:
        break;
    }
    return isError;
  }


    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <Paper
            sx={{
              width: "595px",
              height: "100%",
            }}
          >
            <IconButton
              onClick={onClose}
              sx={{
                position: "absolute",
                top: "8px",
                right: "8px",
                color: "var(--light-mode-gray-80, #5C677D)",
              }}
            >
              <CloseIcon />
            </IconButton>
            {isFormLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <CircularProgress />
              </Grid>
            ) : checkIsError() ?
            (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Typography>
                  Some Error Occurred !! Please Retry.
                </Typography>
              </Grid>
            )
            :(
              component
            )}
          </Paper>
        </Box>
      </Modal>
    );
  };

  export default CustomAddModal;
