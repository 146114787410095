import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import ProfileSection from "../ProfileSection";
import TotalTransactions from "./icons/TotalTransactions";
import Users from "./icons/Users";
import TransactionChart from "./TransactionChart";
import RevenueChart from "./RevenueChart";
import { useSubscription } from "@apollo/client";
import {
  API_CHART_DATA_WITHOUT_ALL_SUBSCRIPTION,
  API_CHART_DATA_WITH_ALL_SUBSCRIPTION,
  API_TRANSACTIONS_SUBSCRIPTION,
  API_TRANSACTIONS_WITH_ALL_SUBSCRIPTION,
  API_USERS_SUBSCRIPTION,
  API_USERS_WITH_FACILITY_SUBSCRIPTION
} from "../../graphql/query";
import UnitsConsumed from "../UnitsConsumed";
import TotalRevenue from "../TotalRevenue";
import TotalProfit from "../TotalProfit";
import FacilityFilterBtn from "../FacilityFilter";
import { useSelector, useDispatch } from "react-redux";
import ChargeUsageStatue from "./ChargeUsageStatue";
import UnitsConsumedChart from "./UnitsConsumedChart";
import TotalCompanyProfit from "../TotalCompanyProfit";
import { useTranslation } from 'react-i18next';
import NewDatePicker from "../NewDatePicker";
import ChargerUsageChart from "../ChargerUsageChart";
import ElectricityConsumed from "../ElectricityConsumed";
import CircularProgress from '@mui/material/CircularProgress';


const greetingMessage = () => {
  const hour = (new Date()).getHours();
  if (hour > 0 && hour < 12)
    return 'Good Morning';
  else if (hour >= 12 && hour < 18)
    return 'Good Afternoon';
  else
    return 'Good Evening';
}
const customizeName = (name) => {
  let newName = name.split(' ').at(0);
  newName = newName.charAt(0).toUpperCase() + newName.substring(1);
  return newName;
}


const Overview = () => {
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const { selectedDate } = useSelector(state => state.datePicker);
  const { t } = useTranslation();
  const [pageLoading, setPageLoading] = useState(true);
  const { selectedFacility } = useSelector(state => state.stationList);
  const variable = {
    start: selectedDate?.start?.toISOString(), end: selectedDate?.end?.toISOString()
  }
  const { data } = useSubscription(selectedFacility?.name === 'All' ? API_TRANSACTIONS_WITH_ALL_SUBSCRIPTION : API_TRANSACTIONS_SUBSCRIPTION, {
    variables: { ...variable, facility_id: selectedFacility?.id }
  });
  const { data: data2 } = useSubscription(selectedFacility?.name === 'All' ? API_USERS_SUBSCRIPTION : API_USERS_WITH_FACILITY_SUBSCRIPTION,
    {
      variables: { ...variable, facility_id: selectedFacility?.id }
    });
  const userData = JSON.parse(localStorage.getItem('userData'));
  const api = !selectedFacility?.is_fleet ? API_CHART_DATA_WITH_ALL_SUBSCRIPTION : API_CHART_DATA_WITHOUT_ALL_SUBSCRIPTION;
  const { data: data3 } = useSubscription(api, {
    variables: { ...variable, facility_id: selectedFacility?.id }
  });
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [id, setId] = useState(selectedFacility?.id)



  useEffect(() => {
    if (selectedFacility.id.length || selectedFacility.id > 0) setPageLoading(false)
  }, [selectedFacility])

  useEffect(() => {
    setTotalUsers(0);
    setTotalTransactions(0)
  }, [id, selectedFacility])

  useEffect(() => {
    setLoading(true)
    if (data && data.view_facility_revenue_aggregate && data.view_facility_revenue_aggregate.aggregate) {
      setTotalTransactions(data.view_facility_revenue_aggregate.aggregate.count ?? 0);
      setLoading(false)
    }
  }, [data, selectedDate, selectedFacility])

  useEffect(() => setId(selectedFacility?.id), [selectedFacility])



  useEffect(() => {
    setUserLoading(true);
    if (data2 && data2.view_facility_revenue_aggregate) {
      setTotalUsers(data2.view_facility_revenue_aggregate.aggregate.count ?? 0);
      setUserLoading(false);
    }
  }, [data2, selectedDate, selectedFacility])

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={3} alignItems='center'>
        <Grid item xs={8} style={{ flexGrow: 1, padding: '0 20px' }}>
          <Typography variant="h4" fontSize='32px' fontWeight='600'>{greetingMessage()},
            <Typography variant="subtitle" color='#467CEB'> {customizeName(userData?.data?.name ?? '')} !
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <ProfileSection />
        </Grid>
      </Grid>
      {pageLoading ?
        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "70vh" }}><CircularProgress /></Box> : (
          <>
            <Grid container mb='20px' columnSpacing={2} alignItems='center'>
              <Grid item>
                <NewDatePicker />
              </Grid>
              <Grid item >
                <FacilityFilterBtn />
              </Grid>
            </Grid>
            <Grid container spacing={3} columnSpacing={3} sx={{ marginBottom: 2 }}>
              <Grid item xs={3}>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ flexGrow: 1 }}
                  alignItems="center"
                  rowSpacing={3}
                >
                  <Grid item xs={12}>
                    <TotalRevenue dateFilter={selectedDate} facilityFilter={selectedFacility} />
                  </Grid>
                  <Grid item xs={12}>
                    <ElectricityConsumed dateFilter={selectedDate} facilityFilter={selectedFacility} />
                  </Grid>
                  <Grid item xs={12}>
                    <TotalCompanyProfit dateFilter={selectedDate} facilityFilter={selectedFacility} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9} style={{ flexGrow: 1 }}>
                <UnitsConsumedChart dateFilter={selectedDate} facilityFilter={selectedFacility} data={data3} />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" columnSpacing={3}>

              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  rowSpacing={3}
                  columnSpacing={3}
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xs={3}>
                    <TotalProfit dateFilter={selectedDate} facilityFilter={selectedFacility} />
                  </Grid>
                  <Grid item xs={3}>
                    <UnitsConsumed dateFilter={selectedDate} facilityFilter={selectedFacility} />
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      bgcolor={"white"}
                      justifyContent={"space-between"}
                      borderRadius={"16px"}
                      padding={"24px"}
                      height='130px'
                    >
                      <Typography>
                        <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>{t('totalTransactions')}{loading ? ` (${t('loading')}...)` : ''}</Typography>
                        <Typography variant="h5" fontSize='24px' fontWeight='700' sx={{ marginTop: '10px' }} style={{ textWrap: 'nowrap' }}>{totalTransactions}</Typography>
                      </Typography>
                      <TotalTransactions />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      bgcolor={"white"}
                      justifyContent={"space-between"}
                      borderRadius={"16px"}
                      padding={"24px"}
                      height='130px'
                    >
                      <Typography>
                        <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>{t('totalUsers')}{userLoading ? ` (${t('loading')}...)` : ''}</Typography>
                        <Typography variant="h5" fontSize='24px' fontWeight='700' sx={{ marginTop: '10px' }} style={{ textWrap: 'nowrap' }}>{totalUsers}</Typography>
                      </Typography>
                      <Users />
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="start"
                  sx={{ flexGrow: 1 }}
                  alignItems="center"
                  rowSpacing={3}
                  columnSpacing={3}
                >
                  <Grid item xs={8.5}>
                    <TransactionChart dateFilter={selectedDate} facilityFilter={selectedFacility} data={data3} height='411' />
                  </Grid>
                  <Grid item md={3.5}>
                    <ChargeUsageStatue facilityFilterID={selectedFacility.id} facilityFilterName={selectedFacility.name} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} pt={2}>
              <Grid item xs={12}>
                <RevenueChart dateFilter={selectedDate} facilityFilter={selectedFacility} data={data3} height={226} />
              </Grid>
              {
                selectedFacility?.name !== 'All' &&
                <Grid item xs={12} pt={2}>
                  <ChargerUsageChart dateFilter={selectedDate} facilityFilter={selectedFacility} />
                </Grid>
              }
            </Grid>
          </>
        )}
    </>
  );
};
export default Overview;
