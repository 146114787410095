import React, { useState } from "react";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TableContainer,
  TablePagination,
} from "@mui/material";
import CustomModal from "./customModal";
import EmptyData from "../EmptyData";
import noLogs from "../../assets/emptyData/noLogs.svg"

const DetailsTable = ({ detailsData }) => {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  if (!detailsData || !detailsData.tabs || !detailsData.tabs[0]) {
    return null;
  }

  const headRows = detailsData?.tabs[0].headers;
  const tableContent = detailsData?.tabs[0].table_content;

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...tableContent].sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === "string" && typeof bValue === "string") {
      return order === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else {
      return order === "asc" ? aValue - bValue : bValue - aValue;
    }
  });

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLogClick = (event, content) => {
    event.preventDefault();
    setModalContent(content);
    setModalOpen(true);
  };

  const renderCellContent = (content, columnId) => {
    if (content === null || content === undefined) {
      return "";
    }

    if (typeof content === "object" && Object.keys(content)?.length === 0) {
      return "";
    }

    if (columnId === "log" && typeof content === "object" && Object.keys(content)?.length > 0) {
      return (
        content?.content ? (
          <a href="#" onClick={(e) => handleLogClick(e, content?.content)}>
            {content?.label}
          </a>
        ) : (
          `${content?.label}`
        )
      );
    }
    if (["created_at", "updated_at"].includes(columnId) && content) {
      const formattedDate = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      };
      const dateObject = new Date(content);
      const formatted = dateObject.toLocaleString("en-US", formattedDate);
      return formatted;
    }
    return content.toString();
  };
  const formatJSON = (jsonString) => {
    try {
      const jsonObj = JSON.parse(jsonString);
      return JSON.stringify(jsonObj, null, 2);
    } catch (error) {
      console.error("Error formatting JSON:", error);
      return jsonString;
    }
  };

  return (
    <>
      {tableContent.length > 0 ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            style={{ boxShadow: "none" }}
            sx={{
              height: "calc(100vh - 290px)",
              width: "100%",
            }}
          >
            <Box
              sx={{
                boxSizing: "border-box",
                width: "100%",
                display: "table",
                tableLayout: "fixed",
              }}
            >
              <TableContainer
                component={Paper}
                style={{ maxHeight: "470px", boxShadow: "none" }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {headRows.map((row) => (
                        <TableCell
                          key={row.id}
                          sortDirection={orderBy === row.id ? order : false}
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            whiteSpace: "nowrap",
                            textTransform: "uppercase",
                            position: "sticky",
                            backgroundColor: "#EFF4FF",
                            zIndex: 1,
                            paddingLeft: "20px",
                          }}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={orderBy === row.id ? order : "asc"}
                            onClick={() => handleSort(row.id)}
                          >
                            {row.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          {headRows.map((headRow) => (
                            <TableCell
                              key={headRow.id}
                              style={{ padding: "20px" }}
                            >
                              {renderCellContent(row[headRow.id], headRow.id)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[30, 60, 90]}
                component="div"
                count={tableContent.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <CustomModal open={modalOpen} onClose={() => setModalOpen(false)}>
                {modalContent && (
                  <div>
                    <pre>{formatJSON(JSON.stringify(modalContent))}</pre>
                  </div>
                )}
              </CustomModal>
            </Box>
          </Box>
        </Box>
      ) : (
        <EmptyData
          imagePath={noLogs}
          message="No Logs Found"
          height="calc(100vh - 250px)"
        />
      )}
    </>
  );
};

export default DetailsTable;
