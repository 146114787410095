import React, { useEffect } from "react";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  Grid,
  Container,
} from "@mui/material";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppRoutes from "./routesSetup/AppRoutes";
import { ApolloProvider, useSubscription } from "@apollo/client";
import { client } from "./graphql/client";
import { API_CURRENCY_SUBSCRIPTION } from "./graphql/query";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { useDispatch } from "react-redux";
import { clearLoginState } from "./slice/LoginSlice";
import { setCurrency, setUserCurrency } from "./slice/UserSlice";

function AppRouter() {
  const location = useLocation();
  const { data } = useSubscription(
    API_CURRENCY_SUBSCRIPTION,
    {
      variables: {
        token: localStorage.getItem('token')
      }
    }
  );

  const dispatch = useDispatch();

  // Commented Out Code for Language Change Based on Country Selection.

  // const queryParams = new URLSearchParams(location.search);
  // const selectedLanguage = queryParams.get('lang');

  // useEffect(() => {
  //   if (selectedLanguage) {
  //     i18n.changeLanguage(selectedLanguage);
  //   }
  // }, [selectedLanguage]);

  useEffect(() => {
    window.scrollTo(0,0)
  })

  useEffect(()=>{
    window.addEventListener('localStorageCleared',(e)=>{
      if(!localStorage.getItem('token')){
      dispatch(clearLoginState());
      }
    })
  },[]);

  useEffect(() => {
    const currency = data?.api_user[0]?.api_country?.currency;
    if(currency ){
      localStorage.setItem('currency', currency);
      localStorage.setItem('userCurrency',currency);
      dispatch(setCurrency(currency));
      dispatch(setUserCurrency(currency))
    }
  }, [data]);

  return (
    <Grid
      sx={{
        display: "flex",
        backgroundColor: "#F7F8FA",
        justifyContent:
          location.pathname === "/login" ? "center" : "flex-start",
      }}
    >
      <AppRoutes />
    </Grid>
  );
}

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Inter, sans-serif",
    },
    palette: {
      background: {
        default: "#F7F8FA",
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}> {/* Wrap your AppRouter with I18nextProvider */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container
            maxWidth={true}
            disableGutters
            style={{ backgroundColor: "#F7F8FA" }}
          >
            <Router>
              <AppRouter />
            </Router>
          </Container>
        </ThemeProvider>
      </I18nextProvider>
    </ApolloProvider>
  );
}

export default App;
