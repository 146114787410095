import { useSubscription } from "@apollo/client";
import { API_TOTAL_REVENUE_FACILITY_SUBSCRIPTION, API_TOTAL_REVENUE_WITH_ALL_FACILITY_SUBSCRIPTION } from "../graphql/query";
import { useEffect, useState } from "react";
import ProfitIcon from "./dashboard/icons/ProfitIcon";
import { generateTotalRevenue } from "./TotalRevenue";
import { useSelector } from "react-redux";
import ReactCardFlip from "react-card-flip";
import {
    Typography,
    Box,
    Table,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Paper,
    TableContainer,
    Grid,
    Tooltip,
    IconButton
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import { useTranslation } from 'react-i18next';

const generateFixedValues = (obj, facility, currency = ' ') => {
    if (facility.profit_sharing_type === 'Fixed')
        return {
            type: 'Station Profit',
            units: obj.units_consumed?.toFixed(2),
            price: currency + facility.profit_sharing_amount?.toFixed(2),
            amount: (obj.units_consumed * facility.profit_sharing_amount)?.toFixed(2)
        };
    else
        return {
            percentage: facility.profit_sharing_amount + ' %',
            profit: obj.withoutCompanyProfit,
            amount: ((obj?.withoutCompanyProfit * facility?.profit_sharing_amount) / 100)?.toFixed(2)
        }
}

const TotalCompanyProfit = ({ dateFilter, facilityFilter }) => {

    const api = facilityFilter?.name === 'All' ?
        API_TOTAL_REVENUE_WITH_ALL_FACILITY_SUBSCRIPTION : API_TOTAL_REVENUE_FACILITY_SUBSCRIPTION;
    const variables = {
        variables: {
            start: dateFilter?.start?.toISOString(),
            end: dateFilter?.end?.toISOString(),
            facility_id: facilityFilter?.id
        }
    };

    const { data } = useSubscription(api, variables);
    const [profit, setProfit] = useState(0.00);
    const { currency } = useSelector(state => state.user);
    const stationlist = useSelector(state => state.stationList.data.data);
    const [isFlipped, setIsFlipped] = useState(false);
    const [fixedProfitData, setFixedProfitData] = useState(null);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const [id, setId] = useState(facilityFilter?.id)

    useEffect(() => setId(facilityFilter?.id), [facilityFilter])


    useEffect(() => {
        setProfit(0);
    }, [id, dateFilter])

    useEffect(() => {
        setLoading(true);
        if (facilityFilter?.name !== 'All') {
            if (data?.view_facility_revenue) {
                const facilityValues = generateTotalRevenue(data?.view_facility_revenue, stationlist);
                setProfit((facilityValues.company_profit).toFixed(2));
                setLoading(false);
                if (facilityFilter?.is_profit_sharing)
                    setFixedProfitData(generateFixedValues(facilityValues, facilityFilter, currency));
            }
        }
        else {
            setProfit('N/A');
            setFixedProfitData('N/A')
            setLoading(false);
        }
    }, [data, dateFilter, facilityFilter])

    useEffect(() => {
        setIsFlipped(false);
    }, [dateFilter, facilityFilter])

    const handleFlip = () => {
        setIsFlipped(prev => !prev);
    }


    return (

        <Box>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" flipSpeedFrontToBack="0.6" flipSpeedBackToFront="0.6">
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    bgcolor={"white"}
                    justifyContent={"space-between"}
                    borderRadius={"16px"}
                    padding='24px'
                    height='130px'
                    key="front"
                >
                    <Typography>
                        <Typography variant="subtitle" fontSize='12px' fontWeight='600' style={{ textWrap: 'nowrap' }}>
                            {t('stationProfit')}
                            <Typography variant="subtitle" fontSize='9px' color='grey' pl='3px'>
                                (incl. of all tax)
                            </Typography>
                            {loading ? ` (${t('loading')}...)` : ''}
                        </Typography>
                        <Typography variant="h5" fontSize='24px' fontWeight='700' sx={{ marginTop: '10px' }} style={{ textWrap: 'nowrap' }}>
                            {
                                facilityFilter?.name !== 'All' ?
                                    `${currency} ${profit}`
                                    :
                                    profit
                            }
                        </Typography>
                    </Typography>
                    <ProfitIcon />
                    {
                        facilityFilter?.is_fleet &&
                        <Tooltip title="Info" sx={{ position: "absolute", top: "-3px", right: "0px", color: "grey" }} >
                            <IconButton>
                                <Box onClick={handleFlip} >
                                    <InfoOutlinedIcon />
                                </Box>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
                {facilityFilter?.is_fleet &&
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        borderRadius={"16px"}
                        height='130px'
                        key="back"
                        overflow="hidden"
                    >
                        {
                            facilityFilter?.profit_sharing_type === 'Fixed'
                                ?
                                <Box
                                    width='100%'
                                    height='100%'
                                >
                                    <TableContainer component={Paper} sx={{ height: '100%', width: '100%', overflow: 'hidden', padding: '5px 5px 0', border: "none", backgroundColor: "grey", color: 'white' }}>
                                        <Typography variant="h5" fontSize='12px' fontWeight='600' padding='5px' textAlign='center'>{t('stationProfit')}</Typography>
                                        <Table aria-label="simple table" sx={{ margin: '10px 0 0' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>{t('type')}</TableCell>
                                                    <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>{t('units')}&nbsp;(kWh)</TableCell>
                                                    <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>{currency || ' '}/kWh</TableCell>
                                                    <TableCell align="center" sx={{ padding: '2px', fontSize: '11px', color: 'white' }}>{t('amount')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {
                                                fixedProfitData &&
                                                <TableBody>
                                                    <TableRow
                                                        key={fixedProfitData.type}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row" align="center" sx={{ padding: '2px', fontSize: "10px", color: 'white' }}>
                                                            {fixedProfitData.type}
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '8px', color: 'white' }}>{fixedProfitData.units}</TableCell>
                                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '8px', color: 'white' }}>{fixedProfitData.price}</TableCell>
                                                        <TableCell align="center" sx={{ padding: '2px', fontSize: '8px', color: 'white' }}>{currency || ' '}{fixedProfitData.amount}</TableCell>
                                                    </TableRow>
                                                </TableBody>}
                                        </Table>
                                    </TableContainer>
                                    <Tooltip title="Rotate" sx={{ position: "absolute", top: "-3px", right: "0px", color: "white" }} >
                                        <IconButton>
                                            <Box onClick={handleFlip} >
                                                <ThreeSixtyIcon />
                                            </Box>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                :
                                <Box
                                    width='100%'
                                    height='100%'
                                    backgroundColor="grey"
                                    color='white'
                                    padding="10px 20px"
                                >
                                    {
                                        fixedProfitData &&
                                        <>
                                            <Typography variant="h5" fontSize='12px' fontWeight='600' padding='5px' textAlign='center'>Station Profit</Typography>
                                            <Grid container fontSize='11px'>
                                                <Grid item>
                                                    {fixedProfitData?.percentage} of {fixedProfitData?.profit?.toFixed(2)} =
                                                </Grid>
                                                <Grid item fontWeight='800' paddingLeft='2px'>
                                                    {fixedProfitData?.amount}
                                                </Grid>
                                            </Grid>
                                        </>}
                                    <Tooltip title="Rotate" sx={{ position: "absolute", top: "-3px", right: "0px", color: "white" }} >
                                        <IconButton>
                                            <Box onClick={handleFlip} >
                                                <ThreeSixtyIcon />
                                            </Box>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                        }
                    </Box>
                }
            </ReactCardFlip>
        </Box >
    );
}
export default TotalCompanyProfit;