import React, { useEffect, useState } from "react";
import CustomAddModal from "./CustomAddModal";
import CustomForm from "./CustomForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddStationForm } from "../../slice/AddStationSlice";
import { fetchAddChargerForm } from "../../slice/AddChargerSlice";
import { fetchAddUserForm } from "../../slice/AddUserSlice";
import { fetchEditStationForm } from "../../slice/EditStationSlice";
import { fetchEditChargerForm } from "../../slice/EditChargerSlice";
import { fetchEditUserForm } from "../../slice/EditUserSlice";
import { fetchAddAdminForm } from "../../slice/AddAdminSlice";
import { fetchEditAdminForm } from "../../slice/EditAdminSlice";
import { fetchEditCouponForm } from "../../slice/EditCouponSlice";
import { fetchAddCouponForm } from "../../slice/AddCouponSlice";
import { fetchGetConfigurationForm } from "../../slice/GetConfigurationSlice";

const DynamicFormModal = ({ isOpen, onClose, id, editTabEndpoint, getConfigurationTabEndpoint }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formData = useSelector((state) => state.addStation.data.data);
  const chargerFormData = useSelector((state) => state.addCharger.data.data);
  const userData = useSelector((state) => state.addUser.data.data);
  const adminData = useSelector((state) => state.addAdmin?.data?.data);
  const editStationData = useSelector((state) => state.editStation.data.data);
  const editChargerData = useSelector((state) => state.editCharger.data.data);
  const editUserData = useSelector((state) => state.editUser.data.data);
  const editAdminData = useSelector((state) => state.editAdmin.data.data);
  const editCouponData = useSelector(state => state.editCoupon.data.data);
  const addCoupon = useSelector((state) => state.addCoupon.data.data);
  const getConfiguration = useSelector((state) => state?.getConfiguration?.data?.data);
  const dispatch = useDispatch();

  const modalType = localStorage.getItem('dynamicModalType');

  if (modalType === "editCharger" && editTabEndpoint !== undefined)
    localStorage.setItem("editChargerEndpoint", editTabEndpoint)
  else if (modalType === "getConfiguration" && getConfigurationTabEndpoint !== undefined)
    localStorage.setItem("getConfigurationTabEndpoint", getConfigurationTabEndpoint)

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isModalOpen) {
      switch (modalType) {
        case "addStation":
          dispatch(fetchAddStationForm());
          break;
        case "addCharger":
          dispatch(fetchAddChargerForm(id));
          break;
        case "addUser":
          dispatch(fetchAddUserForm(id));
          break;
        case "addAdmin":
          dispatch(fetchAddAdminForm(id));
          break;
        case "addCoupon":
          dispatch(fetchAddCouponForm());
          break;
        case "editStation":
          dispatch(fetchEditStationForm(id))
          break;
        case "editCoupon":
          dispatch(fetchEditCouponForm(id))
          break;
        case "editCharger":
          dispatch(fetchEditChargerForm(editTabEndpoint))
          break;
        case "editUser":
          dispatch(fetchEditUserForm(editTabEndpoint))
          break;
        case "editAdmin":
          dispatch(fetchEditAdminForm(editTabEndpoint))
          break;
        case "getConfiguration":
          dispatch(fetchGetConfigurationForm(getConfigurationTabEndpoint))
          break;
        default:
          break;
      }
    }
  }, [dispatch, id, editTabEndpoint, isModalOpen]);

  const renderForm = () => {
    let formProps;
    switch (modalType) {
      case "addStation":
        formProps = { data: formData, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "addCharger":
        formProps = { data: chargerFormData, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "addUser":
        formProps = { data: userData, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "addCoupon":
        formProps = { data: addCoupon, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "addAdmin":
        formProps = { data: adminData, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "editStation":
        formProps = { data: editStationData, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "editCharger":
        formProps = { data: editChargerData, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "editUser":
        formProps = { data: editUserData, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "editAdmin":
        formProps = { data: editAdminData, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "editCoupon":
        formProps = { data: editCouponData, onModalClose: onClose, id: id, modalType: modalType };
        break;
      case "getConfiguration":
          formProps = { data: getConfiguration, onModalClose: onClose, id: id, modalType: modalType };
          break;
      default:
        break;
    }

    return (
      <CustomAddModal
        isOpen={isOpen}
        onClose={onClose}
        modalType={modalType}
        component={<CustomForm props={formProps} />}
      />
    );
  }

  return renderForm();
};

export default DynamicFormModal;

